import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Employer Branding auf YouTube",
  "description": "Bilder bewegen und sie begeistern Menschen – die Stärke des Video-Kanals YouTube",
  "author": "Anna-Mai Petersen",
  "categories": ["tutorials"],
  "date": "2021-07-30T00:00:00.000Z",
  "featured": false
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Bilder bewegen und sie begeistern Menschen – die Stärke des Video-Kanals YouTube. Auf YouTube findet man unterschiedliche Inhalte zu zahlreichen Themen. Sie haben als Arbeitgeber durch Videos die Chance das Unternehmen authentisch zu präsentieren, indem sie Mitarbeiter zu Wort kommen lassen und einzelne Bereiche vorstellen. Dies gibt potentiellen Bewerbern einen Einblick, wie ein potentieller Mitarbeiter im Unternehmen integriert wird. Der YouTube Kanal kann mit der Webseite oder anderen Social Media Kanälen verknüpft werden, um z. B. Videos auf demKarriereportal einzubinden.`}</p>
    <p>{`So erstellen Sie einen Recruiting-Video-Kanal`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Öffnen Sie Youtube.com und melden Sie sich dort mit Ihrem Google-Konto an.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Klicken Sie oben rechts auf das Profil-Symbol und wählen Sie im Menü "Mein Kanal" aus.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Geben Sie einen Namen für Ihren Kanal ein. Diesen können Sie völlig frei wählen.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Ihr Kanal ist somit erstellt und Sie können in der Übersicht auf "Kanal anpassen" klicken oder das Creator Studio öffnen.`}</p>
      </li>
    </ul>
    <p>{`Detaillierte Anleitung:
`}<a parentName="p" {...{
        "href": "https://blog.hubspot.de/marketing/youtube-kanal-erstellen"
      }}>{`https://blog.hubspot.de/marketing/youtube-kanal-erstellen`}</a></p>
    <h2>{`Was ist ein Brand-Kanal?`}</h2>
    <p>{`Wenn ein YouTube-Kanal mit einem Brand-Konto verknüpft ist, können mehrere Nutzer diesen Kanal über ihr Google-Konto verwalten. Wenn du schon einen YouTube-Kanal hast, kannst du ihn einem bestimmten Brand-Konto zuweisen.`}</p>
    <p><a parentName="p" {...{
        "href": "https://support.google.com/youtube/answer/3056283?hl=de"
      }}>{`https://support.google.com/youtube/answer/3056283?hl=de`}</a></p>
    <h2>{`Wie lade ich ein Video hoch?`}</h2>
    <p>{`Organisieren Sie die Video-Inhalte Ihres Unternehmens am besten vorab in seht guter Qualität als MP4-Datei z.b. von Ihrer Agentur oder Marketingabteilung.
`}<a parentName="p" {...{
        "href": "https://support.google.com/youtube/answer/57407?co=GENIE.Platform%3DDesktop&hl=de"
      }}>{`https://support.google.com/youtube/answer/57407?co=GENIE.Platform%3DDesktop&hl=de`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      